<template>
  <component :is="component"
             :params="params"
             :invoice="invoice"
             :transaction="transaction"
             @reload="$emit('reload')"></component>
</template>

<script>
import PayMixin from "@/components/PayMixin";
import QfpayWidget from "@/components/providers/QfpayWidget";
import FirstDataWidget from "@/components/providers/FirstDataWidget";
import PayMeWidget from "@/components/providers/PayMeWidget";
import MPayWidget from "@/components/providers/MPayWidget";
import UnsupportedProvider from "@/components/providers/UnsupportedProvider";
import CyberSourceWidget from "@/components/providers/CyberSourceWidget";
import AsiaPayWidget from "@/components/providers/AsiaPayWidget";
import AtomeWidget from "@/components/providers/AtomeWidget";

export default {
  name: "PayWidget",
  mixins: [PayMixin],
  computed: {
    component() {
      switch (this.transaction.provider) {
        case 'qfpay': return QfpayWidget;
        case 'firstdata': return FirstDataWidget;
        case 'cybersource': return CyberSourceWidget;
        case 'payme': return PayMeWidget;
        case 'mpay': return MPayWidget;
        case 'asiapay': return AsiaPayWidget;
        case 'atome': return AtomeWidget;
      }
      return UnsupportedProvider;
    }
  }
}
</script>